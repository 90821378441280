import { requestPublicToken } from "@/utils/auth_proxies";
import {
  isOtpWhatsappOnRegisterActive,
  isSocialHidden,
  isOtpWhatsappActive,
  isPinLoginActive,
  isSkipDanaBindingAfterRegistration,
  isOtpWaUntrustedLoginActive,
  isBukumitraBusinessInformation,
  isActivatePinAfterLoginActive,
  isActivatePinAfterRegisterActive,
  isExistingUserNonMitra,
} from "@/utils/app";

export const accountsRuntimeContext = {
  install: async function (Vue, options = { wrapper: "" }) {
    const { wrapper } = options;
    const { APP } = global["__ACCOUNTS_FE_CONTEXT__"];
    const { showFacebookAuthBtn, showGoogleAuthBtn, showAppleAuthBtn } = APP;
    Vue.prototype.$accounts = global["__ACCOUNTS_FE_CONTEXT__"];
    Vue.prototype.$showFacebook = showFacebookAuthBtn === "true";
    Vue.prototype.$showGoogle = showGoogleAuthBtn === "true";
    Vue.prototype.$showApple = showAppleAuthBtn === "true";
    Vue.prototype.$isSocialHidden = isSocialHidden(wrapper);
    Vue.prototype.$isOtpWhatsappActive = isOtpWhatsappActive(wrapper);
    Vue.prototype.$isPinLoginActive = isPinLoginActive(wrapper);
    Vue.prototype.$isOtpWhatsappOnRegisterActive =
      isOtpWhatsappOnRegisterActive(wrapper);
    Vue.prototype.$isOtpWaUntrustedLoginActive =
      isOtpWaUntrustedLoginActive(wrapper);
    Vue.prototype.$isSkipDanaBindingAfterRegistration =
      isSkipDanaBindingAfterRegistration(wrapper);
    Vue.prototype.$isBukumitraBusinessInformation =
      isBukumitraBusinessInformation(wrapper);
    Vue.prototype.$isActivatePinAfterLoginActive =
      isActivatePinAfterLoginActive(wrapper);
    Vue.prototype.$isActivatePinAfterRegisterActive =
      isActivatePinAfterRegisterActive(wrapper);
    Vue.prototype.$isExistingUserNonMitra = isExistingUserNonMitra(wrapper);
    Vue.prototype.$accessToken = await requestPublicToken();
  },
};
