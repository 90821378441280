export const getClientIdUrl = (url) => {
  try {
    const decodedUrl = decodeURIComponent(url);
    const clientId = new URL(decodedUrl).searchParams.get("client_id");

    if (!clientId) {
      return null;
    }

    return clientId;
  } catch (error) {
    return null;
  }
};
