import { generateClientRequestId } from "@/utils/generate_client_request_id";

export const handleResponse = (response) => {
  if (response?.headers?.get("content-type") === "text/html") {
    return response.text().then((data) => {
      return getResponse(response, data, "text/html");
    });
  }
  return response.json().then((data) => {
    return getResponse(response, data, "application/json");
  });
};

const getResponse = (response, data, type) => {
  const returnResponse = {
    [response.ok ? "response" : "errors"]: data,
    type,
    meta: response,
  };

  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve(returnResponse);
    } else {
      reject(returnResponse);
    }
  });
};

export function headersFetch(headers = {}) {
  return Object.assign(headers, {
    "Content-Type": "application/json",
    "X-Request-Id": generateClientRequestId(),
  });
}

export function isResponseTypeHtml(response) {
  return response?.headers?.get("content-type") === "text/html";
}
