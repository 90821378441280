export function isSocialHidden(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset.socialHidden === "true"
  );
}

export function isOtpWhatsappActive(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset.otpWhatsappActive === "true"
  );
}

export function isPinLoginActive(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset.pinLoginActive === "true"
  );
}

export function isOtpWhatsappOnRegisterActive(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset.otpWaOnRegisterActive ===
    "true"
  );
}

export function isOtpWaUntrustedLoginActive(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset
      .otpWaUntrustedLoginActive === "true"
  );
}

export function isSkipDanaBindingAfterRegistration(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset
      .skipDanaBindingAfterRegistration === "true"
  );
}

export function isBukumitraBusinessInformation(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset
      .bukumitraBusinessInformation === "true"
  );
}

export function isActivatePinAfterLoginActive(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset
      .activatePinAfterLoginActive === "true"
  );
}

export function isActivatePinAfterRegisterActive(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset
      .activatePinAfterRegisterActive === "true"
  );
}

export function isExistingUserNonMitra(wrapperSelector) {
  if (!wrapperSelector) return false;
  return (
    document.querySelector(wrapperSelector).dataset.existingUserNonMitra ===
    "true"
  );
}

export default {
  isSocialHidden,
  isOtpWhatsappActive,
  isPinLoginActive,
  isOtpWhatsappOnRegisterActive,
  isOtpWaUntrustedLoginActive,
  isSkipDanaBindingAfterRegistration,
  isBukumitraBusinessInformation,
  isActivatePinAfterLoginActive,
  isActivatePinAfterRegisterActive,
};
