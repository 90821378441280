import { getClientIdUrl } from "./get_client_id_url";
import { v4 as uuid } from "uuid";

const accounts = global["__ACCOUNTS_FE_CONTEXT__"];
const {
  APP: { clientId: webClientId },
} = accounts;

const QUERY_CLIENT_REQUEST_ID = "client_request_id";
let clientRequestId;

export function setClientRequestId(id) {
  clientRequestId = id;
}

export function generateClientRequestId() {
  if (clientRequestId) {
    return clientRequestId;
  }

  const clientRequestIdUrl = new URLSearchParams(window.location.search).get(
    QUERY_CLIENT_REQUEST_ID,
  );

  if (clientRequestIdUrl) {
    setClientRequestId(clientRequestIdUrl);
    return clientRequestId;
  }

  const clientId = getClientIdUrl(window.location.href) || webClientId;
  setClientRequestId(`${clientId}_${uuid()}`);
  return clientRequestId;
}
