import { handleResponse, headersFetch } from "@/utils/fetch";
import { csrf } from "./csrf";

const {
  APP: { clientId: webClientId, apiAuthEndpoint },
} = global["__ACCOUNTS_FE_CONTEXT__"];

let hasFetch = false;
let accessToken = null;

import { getClientIdUrl } from "@/utils/get_client_id_url";

export function requestPublicToken() {
  if (hasFetch) return accessToken;
  const comebackUrl = new URL(window.location.href).searchParams.get(
    "comeback",
  );

  let urlClientId;
  if (comebackUrl) {
    urlClientId = getClientIdUrl(comebackUrl);
  }

  return fetch(apiAuthEndpoint, {
    method: "post",
    headers: headersFetch(),
    body: JSON.stringify({
      authenticity_token: csrf.token,
    }),
  })
    .then(handleResponse)
    .then(({ response: { access_token } }) => {
      hasFetch = true;
      accessToken = access_token;
      return accessToken;
    })
    .catch(() => false);
}
